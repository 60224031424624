Vue.component('itemSliderComponent',{
    props:['item','container',"stockFlag","showCarusel"],
    data:function(){
        return {
            slidesItem:[],
            selected:0,
            itemsPerPage:3,
            minIndex:0,
            maxIndex:3,
        }
    },
    mounted:function(){
        this.getItemImage()
    },
    methods:{
        getItemImage:function(){
            if(this.item.images && this.item.images.length > 0) {
                this.slidesItem = this.item.images;
                /*
                let imagesList = this.item.slideIds.split(",");
                for (let imgId of imagesList) {
                    let dataDesTruct = imgId.split("--");
                    this.slidesItem.push(`/oo/api/get_attached_file?OriginRecordName=Item&OriginId=${this.item.Code}&id=${imgId}&ts=${md5(dataDesTruct[0])}`);
                    //this.slidesItem.push(`/oo/api/get_attached_file?OriginRecordName=Item&OriginId=${this.item.Code}&id=${item}`);
                }*/
                return this.slidesItem[0];
            }
            return []
        },
        shouldRenderImg(index) {
            console.log('evaluate',{index},this.minIndex ,this.maxIndex);
            return (index>=this.minIndex && index<this.maxIndex);
        },
        moveLeft(){
            if(this.minIndex>1) {
                this.minIndex = this.minIndex - 1;
                this.maxIndex = this.maxIndex - 1;
            }/*else {
                this.minIndex = this.minIndex =  this.slidesItem.length-3;
                this.maxIndex = this.maxIndex = this.slidesItem.length-1;
            }*/

        },
        moveRight(){
            if(this.maxIndex<this.slidesItem.length) {
                this.minIndex = this.minIndex + 1;
                this.maxIndex = this.maxIndex + 1;
            }
            else {
                this.minIndex = this.minIndex = 0;
                this.maxIndex = this.maxIndex = 3;
            }
        }
    },
    computed: {
        noStock:function () {
          if(this.$store.getters.getShowStock)
                if(this.stockFlag!==false)
                    if(this.item.Qty <=0)
                        return true;
          return false;
        },
        renderStock:function () {
            console.log('Show Stock Flag',this.stockFlag,this.$store.getters.getShowStock);
            if(this.stockFlag == undefined || this.stockFlag == null)
                return this.$store.getters.getShowStock;
            return this.stockFlag;
        },

    },
    template:`<div v-if="item.Name">
                 <template v-if="noStock==true">
                    <img v-if="renderStock" src="../static/ecommerce/img/sin_stock.gif" width="190px" height="190px" style="z-index: 1; position: absolute; ">
                 </template>
                 <template v-if="slidesItem.length>0">
                    <template v-if="container">
                        <img class="item-image responsive-img" :src="slidesItem[selected]" alt="Product Image" style="min-height:250px;"/>
                    </template>
                    <template v-else>
                        <img class="item-image responsive-img" :src="slidesItem[selected]" alt="Product Image" />
                    </template>
                 </template>
                 <template v-else>
                    <img class="item-image responsive-img" src="../static/ecommerce/img/nophoto_128x128.jpg"  alt="Item with Out Images" />
                 </template>
                 <template v-if="showCarusel">
                        <div class="col s12">
                            <span v-on:click="moveLeft()"><i class="fas fa-arrow-left" ></i></span>
                            <template v-for="(image,index) of slidesItem">
                                <img class="item-image-thumb responsive-img" :src="image" alt="Product Image"  style="padding:5px;" v-on:click="selected=index"  width="20%"  height="75px"   v-if="shouldRenderImg(index)"/>
                            </template>
                            <span v-on:click="moveRight()"><i class="fas fa-arrow-right fa" ></i></span>
                        </div>
                 </template>
              </div>`
});
